import React from 'react';
import { SignIn } from '@clerk/clerk-react';
import styles from '../styles/Login.module.css';

const Login = () => {
  return (
    <div className={styles.loginContainer}>
      <h1>Log In</h1>
      <SignIn />
    </div>
  );
};

export default Login;
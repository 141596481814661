import React, { useState } from 'react';
import styles from '../styles/NewWorkflow.module.css';
import { violationTypes } from '../questions';

const NewWorkflow = ({ onSave }) => {
  const [platform, setPlatform] = useState('');
  const [violationType, setViolationType] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (platform && violationType) {
      onSave({
        platform,
        violation_type: violationType,
        answers: {},
        appeal_text: '',
      });
    }
  };

  return (
    <div className={styles.newWorkflow}>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="platform">Select Platform:</label>
          <select
            id="platform"
            value={platform}
            onChange={(e) => {
              setPlatform(e.target.value);
              setViolationType('');
            }}
            required
          >
            <option value="">Choose a platform</option>
            {Object.keys(violationTypes).map((p) => (
              <option key={p} value={p}>
                {p.charAt(0).toUpperCase() + p.slice(1)}
              </option>
            ))}
          </select>
        </div>
        {platform && (
          <div className={styles.formGroup}>
            <label htmlFor="violationType">Select Violation Type:</label>
            <select
              id="violationType"
              value={violationType}
              onChange={(e) => setViolationType(e.target.value)}
              required
            >
              <option value="">Choose a violation type</option>
              {violationTypes[platform].map((vt) => (
                <option key={vt} value={vt}>
                  {vt}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className={styles.buttonGroup}>
          <button type="submit" className={styles.button} disabled={!platform || !violationType}>
            Start Workflow
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewWorkflow;
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import styles from '../styles/BlogPost.module.css';
import CreatingEffectiveAmazonAppealLetter from './blog/creating-effective-amazon-appeal-letter';
import UltimateGuideEbayAccountSuspensions from './blog/ultimate-guide-ebay-account-suspensions';
import MasteringEtsyShopReinstatement from './blog/mastering-etsy-shop-reinstatement';
import WalmartMarketplaceSuspensionAppeal from './blog/walmart-marketplace-suspension-appeal';
import CommonAmazonPolicyViolations from './blog/common-amazon-policy-violations';
import CraftingWinningPlanOfAction from './blog/crafting-winning-plan-of-action';

const BlogPost = () => {
  const { id } = useParams();

  const blogPosts = {
    'creating-effective-amazon-appeal-letter': CreatingEffectiveAmazonAppealLetter,
    'ultimate-guide-ebay-account-suspensions': UltimateGuideEbayAccountSuspensions,
    'mastering-etsy-shop-reinstatement': MasteringEtsyShopReinstatement,
    'walmart-marketplace-suspension-appeal': WalmartMarketplaceSuspensionAppeal,
    'common-amazon-policy-violations': CommonAmazonPolicyViolations,
    'crafting-winning-plan-of-action': CraftingWinningPlanOfAction,
  };

  const BlogPostComponent = blogPosts[id];

  if (!BlogPostComponent) {
    return (
      <div className={styles.blogPostContainer}>
        <Link to="/blog" className={styles.backLink}>&larr; Back to Blog</Link>
        <h1>Blog Post Not Found</h1>
        <p>Sorry, the blog post you're looking for doesn't exist.</p>
      </div>
    );
  }

  return (
    <div className={styles.blogPostContainer}>
      <Link to="/blog" className={styles.backLink}>&larr; Back to Blog</Link>
      <BlogPostComponent />
    </div>
  );
};

export default BlogPost;
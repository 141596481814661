import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/Blog.module.css';

const Blog = () => {
  const blogPosts = [
    {
      id: 'creating-effective-amazon-appeal-letter',
      title: 'Master the Art of Amazon Appeal Letters: Reinstate Your Account + Free AI-Powered Templates',
      snippet: 'Learn how to craft a winning Amazon appeal letter to reinstate your suspended seller account. Includes step-by-step guide, free AI-powered templates, and expert tips for success.',
      date: '2023-05-15',
      readTime: '15 min',
      category: 'Amazon',
      featured: true,
    },
    {
      id: 'ultimate-guide-ebay-account-suspensions',
      title: 'The Ultimate Guide to eBay Account Suspensions: Prevention and Recovery',
      snippet: 'Discover how to prevent eBay account suspensions and recover your selling privileges. Includes expert tips, common suspension reasons, and a step-by-step recovery guide.',
      date: '2023-05-20',
      readTime: '12 min',
      category: 'eBay',
    },
    {
      id: 'mastering-etsy-shop-reinstatement',
      title: 'Mastering Etsy Shop Reinstatement: Strategies for Success',
      snippet: 'Learn effective strategies for Etsy shop reinstatement. Includes step-by-step guide, common suspension reasons, and expert tips for crafting a successful appeal.',
      date: '2023-05-22',
      readTime: '10 min',
      category: 'Etsy',
    },
    {
      id: 'walmart-marketplace-suspension-appeal',
      title: 'Walmart Marketplace Suspension: How to Appeal and Get Back to Selling',
      snippet: 'Discover how to appeal a Walmart Marketplace suspension and reinstate your seller account. Includes step-by-step guide, common suspension reasons, and expert tips for a successful appeal.',
      date: '2023-05-24',
      readTime: '11 min',
      category: 'Walmart',
    },
    {
      id: 'common-amazon-policy-violations',
      title: '5 Common Amazon Policy Violations and How to Avoid Them',
      snippet: 'Learn about the 5 most common Amazon policy violations and get expert tips on how to avoid them. Protect your seller account and ensure long-term success on Amazon.',
      date: '2023-05-26',
      readTime: '13 min',
      category: 'Amazon',
    },
    {
      id: 'crafting-winning-plan-of-action',
      title: 'Crafting a Winning Plan of Action for E-commerce Account Reinstatement',
      snippet: 'Master the art of creating a winning Plan of Action (POA) for e-commerce account reinstatement. Includes step-by-step guide, best practices, and expert tips for success across major platforms.',
      date: '2023-05-28',
      readTime: '14 min',
      category: 'E-commerce',
    },
  ];

  const featuredPost = blogPosts.find(post => post.featured);
  const regularPosts = blogPosts.filter(post => !post.featured);

  return (
    <div className={styles.blogContainer}>
      <section className={styles.heroSection}>
        <h1>E-commerce Seller Insights</h1>
        <p>Stay informed with the latest trends, tips, and strategies for e-commerce sellers.</p>
      </section>

      {featuredPost && (
        <section className={styles.featuredPost}>
          <h2>Featured Article</h2>
          <div className={styles.featuredPostContent}>
            <h3><Link to={`/blog/${featuredPost.id}`}>{featuredPost.title}</Link></h3>
            <p className={styles.postMeta}>
              <span>{featuredPost.date}</span> | 
              <span>{featuredPost.readTime} read</span>
            </p>
            <p>{featuredPost.snippet}</p>
            <Link to={`/blog/${featuredPost.id}`} className={styles.readMore}>Read Full Article</Link>
          </div>
        </section>
      )}

      <section className={styles.blogGrid}>
        {regularPosts.map((post) => (
          <article key={post.id} className={styles.blogPost}>
            <h3><Link to={`/blog/${post.id}`}>{post.title}</Link></h3>
            <p className={styles.postMeta}>
              <span>{post.date}</span> | 
              <span>{post.readTime} read</span>
            </p>
            <p className={styles.category}>{post.category}</p>
            <p>{post.snippet}</p>
            <Link to={`/blog/${post.id}`} className={styles.readMore}>Read More</Link>
          </article>
        ))}
      </section>
    </div>
  );
};

export default Blog;
import { createClient } from '@supabase/supabase-js';
import { useAuth, useUser } from '@clerk/clerk-react';
import { useState, useEffect } from 'react';

export function useClerkSupabaseClient() {
  const { getToken } = useAuth();
  const { user } = useUser();
  const [supabase, setSupabase] = useState(null);

  useEffect(() => {
    const createSupabaseClient = async () => {
      const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
      const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

      if (!supabaseUrl || !supabaseAnonKey) {
        console.error('Supabase URL or Anon Key is missing');
        return;
      }

      const token = await getToken({ template: 'supabase' });

      const newClient = createClient(supabaseUrl, supabaseAnonKey, {
        global: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
        auth: {
          persistSession: false,
        },
      });

      setSupabase(newClient);
    };

    if (user) {
      createSupabaseClient();
    }
  }, [user, getToken]);

  return supabase;
}

const ClerkSupabaseAdapter = ({ children }) => {
  return children;
};

export default ClerkSupabaseAdapter;
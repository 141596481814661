import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useUser, SignInButton } from "@clerk/clerk-react";
import styles from '../styles/TemplateDetail.module.css';
import { demoTexts, violationTypes } from '../demosets';

const TemplateDetail = () => {
  const { isLoaded, isSignedIn } = useUser();
  const { platform, templateType } = useParams();
  const [templateContent, setTemplateContent] = useState('');

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      const formattedTemplateType = templateType.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
      setTemplateContent(demoTexts[platform]?.[formattedTemplateType] || "No template available");
    }
  }, [isLoaded, isSignedIn, platform, templateType]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(templateContent).then(() => {
      alert('Template copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const renderContent = () => {
    if (!isSignedIn) {
      return (
        <div className={styles.signInPrompt}>
          <h2>Sign in to view this template</h2>
          <p>Access to individual templates requires an account. It's free to sign up!</p>
          <SignInButton mode="modal">
            <button className={styles.signInBtn}>Sign In</button>
          </SignInButton>
          <Link to="/templates" className={styles.backLink}>Back to Templates</Link>
        </div>
      );
    }

    return (
      <>
        <div className={styles.templateContent}>
          <p>{templateContent}</p>
        </div>
        <button className={styles.copyBtn} onClick={copyToClipboard}>
          Copy to Clipboard
        </button>
      </>
    );
  };

  return (
    <div className={styles.templateDetail}>
      <h1>{platform.charAt(0).toUpperCase() + platform.slice(1)} Template</h1>
      <h2>{templateType.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</h2>
      {renderContent()}
    </div>
  );
};

export default TemplateDetail;
import React from 'react';
import styles from '../styles/PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
  return (
    <div className={styles.privacyPolicy}>
      <h1>Privacy Policy</h1>
      <p>Last updated: [9/17/2024]</p>
      
      <section className={styles.section}>
        <h2>1. Introduction</h2>
        <p>Welcome to Appeal App. We respect your privacy and are committed to protecting your personal data. This privacy policy will inform you about how we look after your personal data when you use our AI-powered appeal generation service and tell you about your privacy rights and how the law protects you.</p>
      </section>

      <section className={styles.section}>
        <h2>2. Data We Collect</h2>
        <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>
        <ul>
          <li>Identity Data: includes first name, last name, username or similar identifier.</li>
          <li>Contact Data: includes email address and telephone numbers.</li>
          <li>Financial Data: includes payment card details (processed securely through our payment provider).</li>
          <li>Transaction Data: includes details about payments to and from you and other details of products and services you have purchased from us.</li>
          <li>Technical Data: includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access our website.</li>
          <li>Profile Data: includes your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses.</li>
          <li>Usage Data: includes information about how you use our website, products and services.</li>
          <li>Appeal Data: includes information you provide for generating appeals, such as account suspension details, platform information, and other relevant data for appeal generation.</li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2>3. How We Use Your Data</h2>
        <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:</p>
        <ul>
          <li>To provide and maintain our appeal generation service</li>
          <li>To process and complete your transactions</li>
          <li>To manage your account and provide you with customer support</li>
          <li>To improve and personalize your experience with our service</li>
          <li>To communicate with you, including sending you relevant notifications about your appeals or our service</li>
          <li>To detect, prevent and address technical issues or potential misuse of our service</li>
          <li>To comply with legal obligations</li>
        </ul>
        <p>We want to be clear that while we use your Appeal Data to generate appeals, we are not responsible for the content of the appeals generated. The AI-generated content is based on the information you provide and should be reviewed by you before use.</p>
      </section>

      <section className={styles.section}>
        <h2>4. Data Sharing and Third Parties</h2>
        <p>We do not sell your personal data to third parties. We may share your data with:</p>
        <ul>
          <li>Service providers who help us operate our business and provide our services (e.g., payment processors, cloud hosting providers)</li>
          <li>Professional advisers including lawyers, bankers, auditors and insurers</li>
          <li>Regulators and other authorities who require reporting of processing activities in certain circumstances</li>
        </ul>
        <p>We require all third parties to respect the security of your personal data and to treat it in accordance with the law.</p>
      </section>

      <section className={styles.section}>
        <h2>5. Data Security</h2>
        <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed. These include:</p>
        <ul>
          <li>Encryption of sensitive data</li>
          <li>Regular security assessments and penetration testing</li>
          <li>Access controls and authentication measures</li>
          <li>Regular backups and disaster recovery processes</li>
        </ul>
        <p>However, no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security.</p>
      </section>

      <section className={styles.section}>
        <h2>6. Your Legal Rights</h2>
        <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to:</p>
        <ul>
          <li>Request access to your personal data</li>
          <li>Request correction of your personal data</li>
          <li>Request erasure of your personal data</li>
          <li>Object to processing of your personal data</li>
          <li>Request restriction of processing your personal data</li>
          <li>Request transfer of your personal data</li>
          <li>Right to withdraw consent</li>
        </ul>
        <p>If you wish to exercise any of these rights, please contact us using the information provided in the "Contact Us" section.</p>
      </section>

      <section className={styles.section}>
        <h2>7. Data Retention</h2>
        <p>We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
      </section>

      <section className={styles.section}>
        <h2>8. Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes.</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
import React, { useState, useEffect, useRef } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/AppealWorkflow.module.css';
import { questionSets } from '../questions';
import { CSSTransition } from 'react-transition-group';
import { useSupabase } from '../supabaseClient';
import CreditMenu from './CreditMenu';
import Pricing from './Pricing';

const AppealWorkflow = ({ workflow, onSave, onCancel, creditBalance, isUsingFreeTrial, onCreditBalanceChange }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const supabase = useSupabase();
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState(workflow.answers || {});
  const [appealText, setAppealText] = useState(workflow.appeal_text || '');
  const [truncatedAppealText, setTruncatedAppealText] = useState(workflow.truncated_appeal_text || '');
  const [recommendedDocuments, setRecommendedDocuments] = useState(workflow.recommended_documents || '');
  const [appealStrategy, setAppealStrategy] = useState(workflow.appeal_strategy || '');
  const [updatedInfo, setUpdatedInfo] = useState('');
  const [showAppeal, setShowAppeal] = useState(!!workflow.appeal_text);
  const [showUpdateInfo, setShowUpdateInfo] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneratingAppeal, setIsGeneratingAppeal] = useState(false);
  const nodeRef = useRef(null);
  const [showCreditMenu, setShowCreditMenu] = useState(false);
  const [showPricingPopup, setShowPricingPopup] = useState(false);

  const questions = questionSets[workflow.platform][workflow.violation_type];

  useEffect(() => {
    if (workflow.appeal_text) {
      setCurrentStep(questions.length);
    }
  }, []);

  useEffect(() => {
    setUnsavedChanges(true);
  }, [answers, appealText, recommendedDocuments, appealStrategy]);

  const handleAnswer = (answer) => {
    setAnswers({ ...answers, [questions[currentStep].id]: answer });
  };

  const handleNextQuestion = () => {
    if (currentStep < questions.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setShowConfirmation(true);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const generateAppeal = async () => {
    setIsGeneratingAppeal(true);
    setErrorMessage('');
    try {
      console.log('Starting appeal generation process');
      if (creditBalance <= 0 && !isUsingFreeTrial) {
        setErrorMessage(`You don't have enough credits to generate an appeal. Please purchase more credits.`);
        setIsGeneratingAppeal(false);
        return;
      }

      console.log('Calling generate-appeal-background function');

      // Save the workflow first and get the updated data
      const savedWorkflow = await handleSave({
        ...workflow,
        appeal_generated: true,
        answers: answers
      });

      if (!savedWorkflow || !savedWorkflow.id) {
        throw new Error('Failed to save workflow before generating appeal');
      }

      const response = await fetch('/.netlify/functions/generate-appeal-background', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.id,
          platform: savedWorkflow.platform,
          suspensionType: savedWorkflow.violation_type,
          answers: savedWorkflow.answers,
          workflowId: savedWorkflow.id,
        }),
      });

      console.log('Response status:', response.status);
      if (response.status !== 202) {
        throw new Error(`Failed to start appeal generation: ${response.statusText}`);
      }

      // Start polling for the appeal generation status
      pollAppealStatus(savedWorkflow.id);
    } catch (error) {
      console.error('Error starting appeal generation:', error);
      setErrorMessage(`An error occurred while starting the appeal generation: ${error.message}. Please try again later.`);
      setIsGeneratingAppeal(false);
    }
  };

  const pollAppealStatus = async (workflowId) => {
    const maxAttempts = 12; // 60 seconds total (5 seconds * 12)
    let attempts = 0;

    const pollInterval = setInterval(async () => {
      attempts++;
      try {
        // Fetch the specific workflow using the workflowId
        const { data, error } = await supabase
          .from('workflows_new')
          .select('id, appeal_text, truncated_appeal_text, appeal_strategy, recommended_documents, is_free_trial')
          .eq('id', workflowId)
          .single();

        if (error) throw error;

        if (!data) {
          throw new Error('Workflow not found');
        }

        if (data.appeal_text && data.appeal_strategy && data.recommended_documents) {
          clearInterval(pollInterval);
          setAppealText(data.appeal_text);
          setTruncatedAppealText(data.truncated_appeal_text);
          setRecommendedDocuments(data.recommended_documents);
          setAppealStrategy(data.appeal_strategy);
          setShowAppeal(true);
          setIsGeneratingAppeal(false);
          setShowConfirmation(false);
          // Automatically select the appeal tab
          setCurrentStep(questions.length);
          console.log('Appeal generation completed successfully');

          // Update the workflow in the database
          await handleSave({
            ...workflow,
            id: workflowId,
            appeal_generated: true,
            answers: answers,
            appeal_text: data.appeal_text,
            truncated_appeal_text: data.truncated_appeal_text,
            recommended_documents: data.recommended_documents,
            appeal_strategy: data.appeal_strategy,
            is_free_trial: data.is_free_trial
          });

          // Update credit balance
          onCreditBalanceChange();
        } else if (attempts >= maxAttempts) {
          clearInterval(pollInterval);
          setIsGeneratingAppeal(false);
          setShowConfirmation(false);
          setErrorMessage('Appeal generation is taking longer than expected. Please check back later.');
        }
      } catch (error) {
        console.error('Error polling appeal status:', error);
        clearInterval(pollInterval);
        setIsGeneratingAppeal(false);
        setShowConfirmation(false);
        setErrorMessage('An error occurred while checking the appeal status. Please try again later.');
      }
    }, 5000); // Poll every 5 seconds

    // Stop polling after 60 seconds (adjust as needed)
    setTimeout(() => {
      clearInterval(pollInterval);
      if (isGeneratingAppeal) {
        setIsGeneratingAppeal(false);
        setErrorMessage('Appeal generation is taking longer than expected. Please check back later.');
      }
    }, 60000);
  };
  
  const renderAppealContent = () => {
    const additionalText = `
    Lorem ipsum odor amet, consectetuer adipiscing elit. Aenean donec facilisi malesuada amet mus posuere quisque cras. Molestie mollis viverra cursus vehicula dignissim curae tellus quis ex. Lobortis maximus tortor bibendum ornare porttitor eros cursus dolor. Nam at litora himenaeos placerat sodales aptent. Donec tincidunt varius pretium ex, condimentum ultricies fusce ac tortor. Blandit laoreet mi luctus arcu quis vel egestas natoque mus.
    Turpis convallis sapien id pharetra elementum maximus aliquet ac etiam. Dapibus accumsan viverra nunc dignissim eget imperdiet elementum sit nunc. Ipsum duis fringilla nibh himenaeos purus quisque. Turpis libero nibh dis ultrices dictumst dictum. Mus faucibus pulvinar ex euismod convallis auctor. Ante hendrerit pharetra velit; facilisis curabitur metus. Imperdiet fringilla etiam mauris sociosqu est. Nunc vestibulum euismod venenatis habitasse dictum fusce.
    Vivamus enim duis gravida sodales a. Sodales nam nisi vestibulum inceptos viverra integer euismod nec. Nostra pharetra hendrerit senectus magnis et a nunc potenti. Nostra amet taciti euismod elit ex vehicula donec. Primis ullamcorper magna gravida taciti nec varius conubia donec. Mattis varius consectetur mattis; nibh mauris ut. Habitant fames eleifend ad bibendum, ac donec. Sapien condimentum eleifend ligula integer ac nostra? Purus a massa primis nostra; inceptos habitasse penatibus. Viverra finibus sociosqu inceptos sollicitudin tincidunt facilisi fusce.
    `;
    const displayedAppeal = isUsingFreeTrial ? `${truncatedAppealText}\n\n\n${additionalText}` : appealText;
  
    return (
      <>
        <div className={styles.appealText}>
          <pre>{displayedAppeal}</pre>
          {isUsingFreeTrial && (
            <div className={styles.fuzzyOverlay}>
              <p>Purchase credits to view the full appeal</p>
              <button onClick={handlePurchaseCredits} className={styles.button}>
                Purchase Credits
              </button>
            </div>
          )}
        </div>
      </>
    );
  };
  
  const handlePurchaseCredits = () => {
    setShowPricingPopup(true);
  };

  const handleClosePricingPopup = () => {
    setShowPricingPopup(false);
  };

  const handleCreditPurchase = () => {
    setShowPricingPopup(false);
    onCreditBalanceChange();
  };

  const copyAppealToClipboard = () => {
    const displayedAppeal = isUsingFreeTrial ? truncatedAppealText : appealText;
    navigator.clipboard.writeText(displayedAppeal).then(() => {
      alert('Appeal copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
      alert('Failed to copy appeal. Please try again.');
    });
  };

  const handleAppealDenied = () => {
    setShowUpdateInfo(true);
    setIsRegenerating(true);
  };

  const handleSave = async (updatedWorkflow = null) => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const workflowToSave = updatedWorkflow || {
        ...workflow,
        answers,
        appeal_text: appealText,
        truncated_appeal_text: truncatedAppealText,
        recommended_documents: recommendedDocuments,
        appeal_strategy: appealStrategy,
        appeal_generated: showAppeal,
      };

      // Ensure user_id is set correctly
      workflowToSave.user_id = user.id;

      // Log the workflow being saved
      console.log('Saving workflow:', workflowToSave);

      // Update the workflow in the database
      const { data, error } = await supabase
        .from('workflows_new')
        .update(workflowToSave)
        .eq('id', workflow.id)
        .select()
        .single();

      if (error) {
        throw error;
      }

      if (!data) {
        throw new Error('No data returned after saving workflow');
      }

      // Log successful save
      console.log('Workflow saved successfully');

      setUnsavedChanges(false);
      
      // Return the saved workflow data
      return data;
    } catch (error) {
      console.error('Error saving workflow:', error);
      setErrorMessage('An error occurred while saving the workflow. Please try again later.');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const renderNavigationLegend = () => {
    return (
      <div className={styles.navigationLegend}>
        {questions.map((question, index) => (
          <div
            key={question.id}
            className={`${styles.legendItem} ${index === currentStep ? styles.active : ''} ${
              answers[question.id] ? styles.completed : ''
            }`}
            onClick={() => setCurrentStep(index)}
          >
            {index + 1}. {question.label.substring(0, 20)}...
          </div>
        ))}
        {showAppeal && (
          <div
            className={`${styles.legendItem} ${currentStep === questions.length ? styles.active : ''} ${styles.completed}`}
            onClick={() => setCurrentStep(questions.length)}
          >
            {questions.length + 1}. Appeal
          </div>
        )}
      </div>
    );
  };

  const renderQuestion = () => {
    if (currentStep === questions.length && showAppeal) {
      return (
        <div className={styles.appealResult}>
          <div className={styles.appealBox}>
            <h3>Generated Appeal</h3>
            {renderAppealContent()}
            <button onClick={copyAppealToClipboard} className={styles.button}>
              Copy Appeal
            </button>
          </div>
          <div className={styles.appealBox}>
            <h3>Recommended Documents</h3>
            <div className={`${styles.recommendedDocuments} ${isUsingFreeTrial ? styles.fuzzyContent : ''}`}>
              {isUsingFreeTrial ? (
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              ) : (
                <ul>
                  {recommendedDocuments.split('\n').map((item, index) => (
                    <li key={index}>{item.trim()}</li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div className={styles.appealBox}>
            <h3>Appeal Strategy</h3>
            <div className={styles.appealStrategy}>
              {appealStrategy.split('\n').map((paragraph, index) => (
                <p key={index}>{paragraph.trim()}</p>
              ))}
            </div>
          </div>
          {!isRegenerating && !isUsingFreeTrial && (
            <button onClick={handleAppealDenied} className={styles.button} disabled={isLoading || isGeneratingAppeal}>
              Appeal Denied?
            </button>
          )}
          {showUpdateInfo && (
            <>
              <h4>Add Updated Information</h4>
              <textarea
                className={styles.updatedInfo}
                value={updatedInfo}
                onChange={(e) => setUpdatedInfo(e.target.value)}
                placeholder="Enter any new information or reasons why the appeal was denied..."
                rows={5}
              />
              <button onClick={handleRegenerateAppeal} className={styles.button} disabled={isLoading || isGeneratingAppeal}>
                Update Info and Regenerate
              </button>
            </>
          )}
        </div>
      );
    }

    const question = questions[currentStep];
    return (
      <CSSTransition
        nodeRef={nodeRef}
        in={true}
        appear={true}
        timeout={300}
        classNames={{
          appear: styles.fadeAppear,
          appearActive: styles.fadeAppearActive,
          enter: styles.fadeEnter,
          enterActive: styles.fadeEnterActive,
          exit: styles.fadeExit,
          exitActive: styles.fadeExitActive,
        }}
      >
        <div ref={nodeRef} className={styles.question}>
          <h3>{question.label}</h3>
          {question.type === 'radio' ? (
            <div className={styles.radioGroup}>
              {question.options.map((option) => (
                <label key={option} className={styles.radioLabel}>
                  <input
                    type="radio"
                    name={question.id}
                    value={option}
                    onChange={() => handleAnswer(option)}
                    checked={answers[question.id] === option}
                  />
                  {option}
                </label>
              ))}
            </div>
          ) : (
            <textarea
              className={styles.textArea}
              onChange={(e) => handleAnswer(e.target.value)}
              value={answers[question.id] || ''}
              placeholder="Type your answer here..."
            />
          )}
          <div className={styles.navigationButtons}>
            <button onClick={handlePreviousQuestion} className={styles.button} disabled={currentStep === 0 || isLoading || isGeneratingAppeal}>
              Previous
            </button>
            {currentStep < questions.length - 1 && (
              <button onClick={handleNextQuestion} className={styles.button} disabled={isLoading || isGeneratingAppeal}>
                Next
              </button>
            )}
            {currentStep === questions.length - 1 && !showAppeal && (
              <button onClick={() => setShowConfirmation(true)} className={styles.button} disabled={isLoading || isGeneratingAppeal || creditBalance === 0}>
                Generate Appeal
              </button>
            )}
          </div>
        </div>
      </CSSTransition>
    );
  };

  return (
    <div className={styles.appealWorkflow}>
      <h2>
        Appeal Workflow: {workflow.platform} - {workflow.violation_type}
      </h2>
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
      {creditBalance !== null && (
        <div className={styles.creditBalanceInfo}>
          Available Credits: {creditBalance}
        </div>
      )}
      <div className={styles.workflowContent}>
        {renderNavigationLegend()}
        <div className={styles.mainContent}>
          {renderQuestion()}
        </div>
      </div>
      <div className={styles.buttonGroup}>
        <button onClick={onCancel} className={styles.button} disabled={isLoading || isGeneratingAppeal}>
          Return to Dashboard
        </button>
        {unsavedChanges && (
          <button onClick={async () => {
            const savedWorkflow = await handleSave();
            if (savedWorkflow) {
              onSave(savedWorkflow);
            }
          }} className={`${styles.button} ${styles.saveButton}`} disabled={isLoading || isGeneratingAppeal}>
            Save Changes
          </button>
        )}
      </div>
      {showConfirmation && (
        <div className={styles.confirmationOverlay}>
          <div className={styles.confirmationDialog}>
            <h3>Generate Appeal</h3>
            {isGeneratingAppeal ? (
              <div className={styles.loadingSpinner}>
                <div className={styles.spinner}></div>
                <p>Generating appeal... Please wait.</p>
                <p>This process may take up to a minute. The page will update automatically when the appeal is ready.</p>
              </div>
            ) : (
              <>
                <p>Are you sure you want to generate an appeal? This will use 1 credit from your balance.</p>
                <div className={styles.confirmationButtons}>
                  <button onClick={generateAppeal} className={`${styles.button} ${styles.confirmButton}`} disabled={isLoading || isGeneratingAppeal}>
                    Yes, Generate Appeal
                  </button>
                  <button onClick={() => setShowConfirmation(false)} className={`${styles.button} ${styles.cancelButton}`} disabled={isLoading || isGeneratingAppeal}>
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {showPricingPopup && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <button onClick={handleClosePricingPopup} className={styles.closeButton}>
              &times;
            </button>
            <Pricing
              onClose={handleClosePricingPopup}
              onPurchaseComplete={handleCreditPurchase}
              isPopup={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AppealWorkflow;
import React from 'react';
import styles from '../styles/TermsOfService.module.css';

const TermsOfService = () => {
  return (
    <div className={styles.termsOfService}>
      <h1>Terms of Service</h1>
      <p>Last updated: [9/17/2024]</p>
      
      <section className={styles.section}>
        <h2>1. Acceptance of Terms</h2>
        <p>Welcome to Appeal App, an online platform that provides AI-powered appeal generation for e-commerce sellers. By using our platform, you agree to be bound by these Terms of Service, our Privacy Policy, and all applicable laws and regulations. If you do not agree with these terms, you should not use our platform.</p>
      </section>

      <section className={styles.section}>
        <h2>2. Description of Service</h2>
        <p>Appeal App provides an AI-powered tool for generating appeals for suspended e-commerce accounts. We reserve the right to modify or discontinue, temporarily or permanently, the Service with or without notice.</p>
      </section>

      <section className={styles.section}>
        <h2>3. User Responsibility</h2>
        <p>You are solely responsible for the use of Appeal App and any legal consequences that may arise from such use. We are not responsible for any loss, damage, or legal liability resulting from your use of our platform. By using Appeal App, you assume all risks associated with its use.</p>
      </section>

      <section className={styles.section}>
        <h2>4. No Legal Advice</h2>
        <p>Appeal App is not a substitute for professional legal advice. The appeal templates and information provided on our platform are for general informational purposes only and should not be considered legal advice. We do not guarantee the accuracy, completeness, or timeliness of any information provided on our platform. Always consult with a qualified legal professional for specific legal matters.</p>
      </section>

      <section className={styles.section}>
        <h2>5. User Account</h2>
        <p>You may need to create an account to access certain features of our platform. You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account or password.</p>
      </section>

      <section className={styles.section}>
        <h2>6. Intellectual Property</h2>
        <p>All content and intellectual property on our platform, including text, graphics, logos, and software, are the property of Appeal App or its licensors and are protected by copyright and other intellectual property laws. You may not reproduce, modify, distribute, or display any of the content or intellectual property on our platform without our prior written consent.</p>
      </section>

      <section className={styles.section}>
        <h2>7. Limitation of Liability</h2>
        <p>We are not liable for any direct, indirect, incidental, special, or consequential damages resulting from your use of Appeal App, even if we have been advised of the possibility of such damages. This includes, but is not limited to, any loss or damage which may be incurred by you as a result of any reliance placed by you on the completeness, accuracy or existence of any information provided through our service.</p>
      </section>

      <section className={styles.section}>
        <h2>8. Indemnification</h2>
        <p>You agree to indemnify and hold Appeal App and its affiliates, officers, agents, and employees harmless from any claim or demand, including reasonable attorneys' fees, arising out of your use of our platform or your violation of these Terms of Service.</p>
      </section>

      <section className={styles.section}>
        <h2>9. Termination</h2>
        <p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>
      </section>

      <section className={styles.section}>
        <h2>10. Governing Law</h2>
        <p>These Terms of Service and your use of Appeal App are governed by and construed in accordance with the laws of [Your Jurisdiction], without giving effect to any principles of conflicts of law.</p>
      </section>

      <section className={styles.section}>
        <h2>11. Changes to Terms</h2>
        <p>We reserve the right to update or modify these Terms of Service at any time without prior notice. Your continued use of Appeal App following any such changes constitutes your acceptance of the revised Terms of Service.</p>
      </section>
      
    </div>
  );
};

export default TermsOfService;
import React from 'react';
import styles from '../styles/Features.module.css';

const Features = () => {
  return (
    <section id="features" className={styles.features}>
      <div className={styles.container}>
        <h2>Key Features</h2>
        <div className={styles.featureGrid}>
          <div className={styles.featureItem}>
            <i className="fas fa-clipboard-list"></i>
            <h3>Platform-Specific Templates</h3>
            <p>Tailored appeals for Amazon, eBay, Etsy, and more.</p>
          </div>
          <div className={styles.featureItem}>
            <i className="fas fa-robot"></i>
            <h3>AI-Powered Generation</h3>
            <p>Advanced algorithms create compelling appeals in minutes.</p>
          </div>
          <div className={styles.featureItem}>
            <i className="fas fa-redo"></i>
            <h3>Appeal Regeneration</h3>
            <p>Refine and perfect your appeals with ease.</p>
          </div>
          <div className={styles.featureItem}>
            <i className="fas fa-save"></i>
            <h3>Save & Manage</h3>
            <p>Keep track of multiple appeal workflows.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
export const questionSets = {
    Amazon: {
        "Policy Violation Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Amazon?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Amazon?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Amazon?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Amazon say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Performance-Related Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Amazon?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Amazon?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Amazon?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Amazon say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Restricted Products Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Amazon?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Amazon?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Amazon?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Amazon say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Multiple Accounts Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Amazon?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Amazon?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Amazon?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Amazon say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Inauthentic or Counterfeit Items Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Amazon?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Amazon?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Amazon?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Amazon say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Review Manipulation Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Amazon?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Amazon?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Amazon?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Amazon say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Late Shipment Rate Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Amazon?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Amazon?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Amazon?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Amazon say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Cancellation Rate Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Amazon?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Amazon?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Amazon?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Amazon say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Customer Service Performance Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Amazon?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Amazon?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Amazon?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Amazon say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Product Safety Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Amazon?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Amazon?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Amazon?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Amazon say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Pricing Error Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Amazon?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Amazon?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Amazon?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Amazon say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Account Information Discrepancy Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Amazon?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Amazon?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Amazon?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Amazon say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Order Defect Rate (ODR) Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Amazon?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Amazon?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Amazon?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Amazon say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Intellectual Property Infringement Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Amazon?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Amazon?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Amazon?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Amazon say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ]
    },
    Ebay: {
        "Policy Violation Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Ebay?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Ebay?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Ebay?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Ebay say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Counterfeit Item Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Ebay?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Ebay?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Ebay?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Ebay say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Intellectual Property Infringement Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Ebay?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Ebay?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Ebay?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Ebay say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Feedback Manipulation Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Ebay?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Ebay?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Ebay?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Ebay say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Shill Bidding Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Ebay?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Ebay?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Ebay?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Ebay say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Unpaid Item Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Ebay?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Ebay?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Ebay?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Ebay say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Unauthorized Item Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Ebay?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Ebay?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Ebay?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Ebay say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ]
    },
    Etsy: {
        "Prohibited Items Policy Violation": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Etsy?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Etsy?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Etsy?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Etsy say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Intellectual Property Infringement": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Etsy?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Etsy?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Etsy?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Etsy say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Handmade Policy Violation": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Etsy?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Etsy?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Etsy?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Etsy say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Reselling Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Etsy?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Etsy?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Etsy?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Etsy say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Counterfeit Item Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Etsy?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Etsy?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Etsy?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Etsy say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Customer Service Performance Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Etsy?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Etsy?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Etsy?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Etsy say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Late Shipping Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Etsy?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Etsy?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Etsy?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Etsy say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ]
    },
    Walmart: {
        "Policy Violation Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Walmart?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Walmart?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Walmart?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Walmart say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Performance-Related Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Walmart?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Walmart?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Walmart?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Walmart say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Prohibited Products Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Walmart?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Walmart?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Walmart?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Walmart say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Intellectual Property Infringement Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Walmart?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Walmart?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Walmart?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Walmart say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Product Quality Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Walmart?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Walmart?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Walmart?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Walmart say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Shipping Performance Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Walmart?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Walmart?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Walmart?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Walmart say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Unauthorized Sales Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Walmart?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Walmart?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Walmart?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Walmart say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Counterfeit Product Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Walmart?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Walmart?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Walmart?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Walmart say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Order Defect Rate (ODR) Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Walmart?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Walmart?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Walmart?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Walmart say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ]
    },
    Shopify: {
        "Policy Violation Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Shopify?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Shopify?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Shopify?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Shopify say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Selling Prohibited or Restricted Products": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Shopify?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Shopify?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Shopify?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Shopify say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Fraudulent or Unauthorized Activity Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Shopify?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Shopify?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Shopify?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Shopify say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Chargeback Rate Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Shopify?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Shopify?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Shopify?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Shopify say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Intellectual Property Infringement Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Shopify?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Shopify?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Shopify?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Shopify say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Misrepresentation of Business Information": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Shopify?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Shopify?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Shopify?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Shopify say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Violation of Payment Processing Terms": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Shopify?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Shopify?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Shopify?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Shopify say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Trademark or Copyright Violation": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Shopify?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Shopify?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Shopify?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Shopify say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ]
    },
    Rakuten: {
        "Policy Violation Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Rakuten?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Rakuten?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Rakuten?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Rakuten say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Intellectual Property Infringement Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Rakuten?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Rakuten?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Rakuten?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Rakuten say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Fraudulent Activity Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Rakuten?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Rakuten?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Rakuten?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Rakuten say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Prohibited Items Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Rakuten?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Rakuten?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Rakuten?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Rakuten say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Late Shipping or Non-Delivery Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Rakuten?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Rakuten?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Rakuten?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Rakuten say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Multiple Account Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Rakuten?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Rakuten?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Rakuten?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Rakuten say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Performance-Based Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Rakuten?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Rakuten?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Rakuten?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Rakuten say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ]
    },
    Alibaba: {
        "Policy Violation Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Alibaba?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Alibaba?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Alibaba?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Alibaba say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Selling Counterfeit Products Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Alibaba?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Alibaba?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Alibaba?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Alibaba say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Intellectual Property Violation": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Alibaba?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Alibaba?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Alibaba?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Alibaba say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Account Misrepresentation Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Alibaba?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Alibaba?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Alibaba?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Alibaba say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Performance-Related Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Alibaba?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Alibaba?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Alibaba?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Alibaba say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Restricted Products Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Alibaba?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Alibaba?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Alibaba?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Alibaba say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Unauthorized Product Sales Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Alibaba?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Alibaba?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Alibaba?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Alibaba say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ]
    },
    Wish: {
        "Policy Violation Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Wish?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Wish?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Wish?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Wish say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Counterfeit or Inauthentic Items Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Wish?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Wish?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Wish?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Wish say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Late Shipping Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Wish?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Wish?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Wish?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Wish say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Restricted Products Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Wish?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Wish?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Wish?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Wish say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Intellectual Property Infringement Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Wish?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Wish?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Wish?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Wish say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Customer Complaints Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Wish?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Wish?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Wish?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Wish say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Poor Seller Performance Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Wish?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Wish?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Wish?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Wish say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ]
    },
    Mercari: {
        "Policy Violation Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Mercari?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Mercari?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Mercari?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Mercari say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Prohibited Items Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Mercari?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Mercari?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Mercari?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Mercari say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Counterfeit Item Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Mercari?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Mercari?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Mercari?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Mercari say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Multiple Accounts Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Mercari?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Mercari?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Mercari?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Mercari say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Intellectual Property Violation Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Mercari?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Mercari?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Mercari?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Mercari say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Fraudulent or Misleading Listings Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Mercari?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Mercari?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Mercari?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Mercari say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Customer Service Issues Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Mercari?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Mercari?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Mercari?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Mercari say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ]
    },
    Newegg: {
        "Policy Violation Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Newegg?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Newegg?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Newegg?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Newegg say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Selling Prohibited Items Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Newegg?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Newegg?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Newegg?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Newegg say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Counterfeit Product Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Newegg?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Newegg?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Newegg?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Newegg say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Intellectual Property Infringement Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Newegg?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Newegg?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Newegg?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Newegg say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Poor Seller Performance Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Newegg?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Newegg?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Newegg?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Newegg say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Shipping and Delivery Violations Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Newegg?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Newegg?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Newegg?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Newegg say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ]
    },
    "Facebook Marketplace": {
        "Policy Violation Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Facebook?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Facebook?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Facebook?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Facebook say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Prohibited Items Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Facebook?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Facebook?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Facebook?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Facebook say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Fraudulent Listing Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Facebook?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Facebook?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Facebook?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Facebook say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Intellectual Property Infringement": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Facebook?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Facebook?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Facebook?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Facebook say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Counterfeit Goods Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Facebook?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Facebook?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Facebook?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Facebook say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Misleading Listings Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Facebook?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Facebook?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Facebook?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Facebook say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Customer Complaints Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Facebook?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Facebook?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Facebook?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Facebook say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ]
    },
    Poshmark: {
        "Policy Violation Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Poshmark?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Poshmark?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Poshmark?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Poshmark say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Counterfeit or Unauthorized Items Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Poshmark?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Poshmark?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Poshmark?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Poshmark say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Multiple Accounts Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Poshmark?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Poshmark?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Poshmark?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Poshmark say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Intellectual Property Infringement": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Poshmark?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Poshmark?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Poshmark?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Poshmark say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Customer Complaint Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Poshmark?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Poshmark?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Poshmark?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Poshmark say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ],
        "Prohibited Item Suspension": [
            {"id": "notificationWording", "label": "What was the exact wording of the performance notification or suspension email from Poshmark?", "type": "textarea"},
            {"id": "immediateActions", "label": "What immediate actions did you take upon receiving the suspension notice?", "type": "textarea"},
            {"id": "priorSuspensions", "label": "Have you had any prior suspensions or warnings from Poshmark?", "type": "radio", "options": ["Yes", "No"]},
            {"id": "sellingDuration", "label": "How long have you been selling on Poshmark?", "type": "text"},
            {"ID": "userExplanation", "label": "In your own opinion, what is the cause of this suspension?", "type": "textarea"},
            {"id": "documentsAvailable", "label": "What documents can you provide, if any, as evidence to help your case?", "type": "textarea"},
            {"id": "failedAppeal", "label": "If you have previously attempted to appeal this suspension, please paste the failed appeal here", "type": "textarea"},
            {"id": "denialNotice", "label": "What did Poshmark say when they denied your previous appeal? (if applicable)", "type": "text"},
            {"id": "sellerName", "label": "What is your personal name, the main one on the account, if any?", "type": "text"},
            {"id": "storeName", "label": "What is the name of your store, if any?", "type": "text"}
        ]
    }
};

export const violationTypes = {
    Amazon: [
        "Policy Violation Suspension",
        "Performance-Related Suspension",
        "Restricted Products Suspension",
        "Multiple Accounts Suspension",
        "Inauthentic or Counterfeit Items Suspension",
        "Review Manipulation Suspension",
        "Late Shipment Rate Suspension",
        "Cancellation Rate Suspension",
        "Customer Service Performance Suspension",
        "Product Safety Suspension",
        "Pricing Error Suspension",
        "Account Information Discrepancy Suspension",
        "Order Defect Rate (ODR) Suspension",
        "Intellectual Property Infringement Suspension"
    ],
    Ebay: [
        "Policy Violation Suspension",
        "Counterfeit Item Suspension",
        "Intellectual Property Infringement Suspension",
        "Feedback Manipulation Suspension",
        "Shill Bidding Suspension",
        "Unpaid Item Suspension",
        "Unauthorized Item Suspension"
    ],
    Etsy: [
        "Prohibited Items Policy Violation",
        "Intellectual Property Infringement",
        "Handmade Policy Violation",
        "Reselling Suspension",
        "Counterfeit Item Suspension",
        "Customer Service Performance Suspension",
        "Late Shipping Suspension"
    ],
    Walmart: [
        "Policy Violation Suspension",
        "Performance-Related Suspension",
        "Prohibited Products Suspension",
        "Intellectual Property Infringement Suspension",
        "Product Quality Suspension",
        "Shipping Performance Suspension",
        "Unauthorized Sales Suspension",
        "Counterfeit Product Suspension",
        "Order Defect Rate (ODR) Suspension"
    ],
    Shopify: [
        "Policy Violation Suspension",
        "Selling Prohibited or Restricted Products",
        "Fraudulent or Unauthorized Activity Suspension",
        "Chargeback Rate Suspension",
        "Intellectual Property Infringement Suspension",
        "Misrepresentation of Business Information",
        "Violation of Payment Processing Terms",
        "Trademark or Copyright Violation"
    ],
    Rakuten: [
        "Policy Violation Suspension",
        "Intellectual Property Infringement Suspension",
        "Fraudulent Activity Suspension",
        "Prohibited Items Suspension",
        "Late Shipping or Non-Delivery Suspension",
        "Multiple Account Suspension",
        "Performance-Based Suspension"
    ],
    Alibaba: [
        "Policy Violation Suspension",
        "Selling Counterfeit Products Suspension",
        "Intellectual Property Violation",
        "Account Misrepresentation Suspension",
        "Performance-Related Suspension",
        "Restricted Products Suspension",
        "Unauthorized Product Sales Suspension"
    ],
    Wish: [
        "Policy Violation Suspension",
        "Counterfeit or Inauthentic Items Suspension",
        "Late Shipping Suspension",
        "Restricted Products Suspension",
        "Intellectual Property Infringement Suspension",
        "Customer Complaints Suspension",
        "Poor Seller Performance Suspension"
    ],
    Mercari: [
        "Policy Violation Suspension",
        "Prohibited Items Suspension",
        "Counterfeit Item Suspension",
        "Multiple Accounts Suspension",
        "Intellectual Property Violation Suspension",
        "Fraudulent or Misleading Listings Suspension",
        "Customer Service Issues Suspension"
    ],
    Newegg: [
        "Policy Violation Suspension",
        "Selling Prohibited Items Suspension",
        "Counterfeit Product Suspension",
        "Intellectual Property Infringement Suspension",
        "Poor Seller Performance Suspension",
        "Shipping and Delivery Violations Suspension"
    ],
    "Facebook Marketplace": [
        "Policy Violation Suspension",
        "Prohibited Items Suspension",
        "Fraudulent Listing Suspension",
        "Intellectual Property Infringement",
        "Counterfeit Goods Suspension",
        "Misleading Listings Suspension",
        "Customer Complaints Suspension"
    ],
    Poshmark: [
        "Policy Violation Suspension",
        "Counterfeit or Unauthorized Items Suspension",
        "Multiple Accounts Suspension",
        "Intellectual Property Infringement",
        "Customer Complaint Suspension",
        "Prohibited Item Suspension"
    ]
};

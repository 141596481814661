import React, { useState } from 'react';
import styles from '../styles/FAQ.module.css';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.faqItem}>
      <h3 className={styles.faqQuestion} onClick={() => setIsOpen(!isOpen)}>
        {question}
        <span className={`${styles.faqIcon} ${isOpen ? styles.open : ''}`}>+</span>
      </h3>
      <div className={`${styles.faqAnswer} ${isOpen ? styles.open : ''}`}>
        <p>{answer}</p>
      </div>
    </div>
  );
};

const FAQ = () => {
  const faqItems = [
    {
      question: "What is Appeal App?",
      answer: "Appeal App is an AI-powered tool that helps e-commerce sellers create compelling appeals for suspended accounts on platforms like Amazon, eBay, and Etsy."
    },
    {
      question: "How does the free trial work?",
      answer: "Our free trial allows you to generate one full appeal in the dashboard. You can experience all the features of our platform before committing to a subscription."
    },
    {
      question: "Can I use Appeal Generator for multiple e-commerce platforms?",
      answer: "Yes, our Pro and Enterprise plans support multiple e-commerce platforms, including Amazon, eBay, Etsy, and Walmart."
    },
    {
      question: "How accurate are the AI-generated appeals?",
      answer: "Our AI-powered system is trained on successful appeal templates and constantly updated. While the accuracy is high, we recommend reviewing and personalizing the appeals before submission."
    },
    {
      question: "Are the appeals garunteed to reinstate my selling account?",
      answer: "Due to the nature of most e-commerce platforms, appeal success is highly dependent on the specific circumstances of your account suspension and the platform's policies. While our AI-powered appeals are designed to provide a well-structured and compelling case based on best practices and platform guidelines, we cannot guarantee the reinstatement of your account. Ultimately, the final decision lies with the platform. However, our system significantly increases your chances by generating tailored, high-quality appeals."
    }
  ];

  return (
    <section id="faq" className={styles.faq}>
      <div className={styles.container}>
        <h2>Frequently Asked Questions</h2>
        <div className={styles.faqGrid}>
          {faqItems.map((item, index) => (
            <FAQItem key={index} question={item.question} answer={item.answer} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
import React, { useState } from 'react';
import styles from '../styles/SavedWorkflows.module.css';

const SavedWorkflows = ({ workflows, onWorkflowClick, onDeleteWorkflow }) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  const handleDeleteClick = (workflow) => {
    if (workflow.appeal_generated) {
      alert("This workflow has a generated appeal and cannot be deleted.");
    } else {
      setDeleteConfirmation(workflow.id);
    }
  };

  const confirmDelete = (id) => {
    onDeleteWorkflow(id);
    setDeleteConfirmation(null);
  };

  return (
    <div className={styles.savedWorkflows}>
      <h2>Saved Workflows</h2>
      {workflows.length === 0 ? (
        <p>No saved workflows yet.</p>
      ) : (
        <ul className={styles.workflowList}>
          {workflows.map((workflow) => (
            <li key={workflow.id} className={styles.workflowItem}>
              <div
                className={styles.workflowInfo}
                onClick={() => onWorkflowClick(workflow)}
              >
                <span className={styles.platform}>{workflow.platform}</span>
                <span className={styles.violationType}>{workflow.violation_type}</span>
                <span className={styles.date}>
                  {new Date(workflow.created_at).toLocaleDateString()}
                </span>
                {workflow.appeal_generated && (
                  <span className={styles.appealGenerated}>Appeal Generated</span>
                )}
              </div>
              {deleteConfirmation === workflow.id ? (
                <div className={styles.confirmDelete}>
                  <p>Are you sure you want to delete this workflow?</p>
                  <button onClick={() => confirmDelete(workflow.id)} className={styles.confirmButton}>
                    Yes, delete
                  </button>
                  <button onClick={() => setDeleteConfirmation(null)} className={styles.cancelButton}>
                    Cancel
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => handleDeleteClick(workflow)}
                  className={styles.deleteButton}
                  disabled={workflow.appeal_generated}
                >
                  Delete
                </button>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SavedWorkflows;
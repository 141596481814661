import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser, useClerk } from '@clerk/clerk-react';
import { loadStripe } from '@stripe/stripe-js';
import styles from '../styles/Pricing.module.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const defaultCreditPackages = [
  { credits: 0, price: 0, name: "Free Trial" },
  { credits: 1, price: 6.99, name: "Basic" },
  { credits: 5, price: 19.99, name: "Pro" },
  { credits: 15, price: 49.99, name: "Enterprise" },
];

const Pricing = ({ onClose, isPopup = false, creditPackages = defaultCreditPackages }) => {
  const navigate = useNavigate();
  const { isSignedIn, user } = useUser();
  const { openSignIn } = useClerk();
  const [error, setError] = useState(null);

  const handleCreditPurchase = async (credits, price) => {
    if (credits === 0) {
      // Handle free trial signup
      navigate('/dashboard');
      return;
    }

    if (isSignedIn) {
      const stripe = await stripePromise;
      
      try {
        const response = await fetch('/.netlify/functions/create-checkout-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            credits,
            price,
            userId: user.id,
            userEmail: user.primaryEmailAddress.emailAddress,
          }),
        });
        
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.error}`);
        }
        
        const session = await response.json();
        const result = await stripe.redirectToCheckout({
          sessionId: session.sessionId,
        });
        
        if (result.error) {
          setError(result.error.message);
        }
      } catch (error) {
        console.error('Error creating checkout session:', error);
        setError(error.message);
      }
    } else {
      openSignIn().then(() => navigate('/dashboard'));
    }
  };

  const renderPricingContent = () => {
    if (!creditPackages || creditPackages.length === 0) {
      return <p className={styles.errorMessage}>No pricing packages available at the moment. Please try again later.</p>;
    }

    return (
      <section className={styles.pricing}>
        <div className={styles.container}>
          <h2 className={styles.title}>Supercharge Your Appeals with AI</h2>
          {error && <p className={styles.errorMessage}>{error}</p>}
          <div className={styles.pricingTable}>
            {creditPackages.map((pkg, index) => (
              <div key={index} className={`${styles.pricingColumn} ${index === 2 ? styles.featured : ''}`}>
                <div className={styles.packageName}>{pkg.name}</div>
                <div className={styles.credits}>{pkg.credits} {pkg.credits === 1 ? 'Credit' : 'Credits'}</div>
                <div className={styles.price}>{pkg.price === 0 ? 'Free' : `$${pkg.price.toFixed(2)}`}</div>
                <button 
                  onClick={() => handleCreditPurchase(pkg.credits, pkg.price)} 
                  className={styles.purchaseButton}
                >
                  {pkg.credits === 0 ? 'Start Free' : 'Purchase'}
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };
  
  if (isPopup) {
    return (
      <div className={styles.pricingPopupOverlay} onClick={onClose}>
        <div className={styles.pricingPopupContent} onClick={(e) => e.stopPropagation()}>
          <button className={styles.closeButton} onClick={onClose}>×</button>
          {renderPricingContent()}
        </div>
      </div>
    );
  }

  return renderPricingContent();
};

export default Pricing;
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, SignInButton } from '@clerk/clerk-react';
import styles from '../styles/Demo.module.css';
import { demoTexts, violationTypes } from '../demosets';

const Demo = () => {
  const [platform, setPlatform] = useState('');
  const [violationType, setViolationType] = useState('');
  const [sellerName, setSellerName] = useState('');
  const [generatedAppeal, setGeneratedAppeal] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const appealContainerRef = useRef(null);
  const { isSignedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isGenerating && currentIndex < generatedAppeal.length) {
      const timer = setTimeout(() => {
        setCurrentIndex(currentIndex + 1);
      }, 2); // Adjust the speed of typing here
      return () => clearTimeout(timer);
    }
  }, [isGenerating, currentIndex, generatedAppeal]);

  useEffect(() => {
    if (isGenerating && appealContainerRef.current) {
      appealContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isGenerating]);

  const handlePlatformChange = (e) => {
    setPlatform(e.target.value);
    setViolationType('');
  };

  const handleViolationTypeChange = (e) => {
    setViolationType(e.target.value);
  };

  const handleSellerNameChange = (e) => {
    setSellerName(e.target.value);
  };

  const generateAppeal = () => {
    if (!platform || !violationType || !sellerName) return;

    let appealText = demoTexts[platform]?.[violationType] || "I apologize, but I don't have a specific template for this violation type. Here's a general structure you can follow:\n\n1. Acknowledge the issue\n2. Explain the root cause\n3. Describe your corrective actions\n4. Outline preventive measures\n5. Request reinstatement";

    appealText = appealText.replace(/\[Seller Name\]/g, sellerName);

    setGeneratedAppeal(appealText);
    setIsGenerating(true);
    setCurrentIndex(0);
  };

  const copyAppeal = () => {
    navigator.clipboard.writeText(generatedAppeal).then(() => {
      alert('Appeal copied to clipboard!');
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const handleRegisterForTrial = () => {
    if (isSignedIn) {
      navigate('/dashboard');
    }
    // If not signed in, the SignInButton component will handle opening the sign-in modal
  };

  return (
    <section id="demo" className={styles.demo}>
      <div className={styles.container}>
        <h2>Experience the Power of AI</h2>
        <div className={styles.demoForm}>
          <div className={styles.formGroup}>
            <label htmlFor="platform-select">Select Platform:</label>
            <select id="platform-select" value={platform} onChange={handlePlatformChange} className={styles.select}>
              <option value="">Choose a platform</option>
              {Object.keys(violationTypes).map((p) => (
                <option key={p} value={p}>{p}</option>
              ))}
            </select>
          </div>
          {platform && (
            <div className={styles.formGroup}>
              <label htmlFor="violation-type-select">Select Violation Type:</label>
              <select id="violation-type-select" value={violationType} onChange={handleViolationTypeChange} className={styles.select}>
                <option value="">Choose a violation type</option>
                {violationTypes[platform].map((v) => (
                  <option key={v} value={v}>{v}</option>
                ))}
              </select>
            </div>
          )}
          {violationType && (
            <div className={styles.formGroup}>
              <label htmlFor="seller-name-input">Enter Your Store Name:</label>
              <input
                id="seller-name-input"
                type="text"
                value={sellerName}
                onChange={handleSellerNameChange}
                placeholder="Your store name"
                className={styles.input}
              />
            </div>
          )}
          {sellerName && (
            <button onClick={generateAppeal} className={styles.button}>
              Generate Appeal
            </button>
          )}
        </div>
        {isGenerating && (
          <div className={styles.appealContainer} ref={appealContainerRef}>
            <div className={styles.demoDisclaimer}>
              <p><strong>Note:</strong> This is a demo version of the appeal generator. The actual app will ask more detailed questions about your specific case to create a highly tailored and effective appeal. This demo provides a general template based on the limited information provided. The free plan includes access to all in depth templates for your use.</p>
            </div>
            <h3>Generated Appeal:</h3>
            <div className={styles.appealText}>
              {generatedAppeal.slice(0, currentIndex)}
            </div>
            {currentIndex === generatedAppeal.length && (
              <div className={styles.appealActions}>
                <button onClick={copyAppeal} className={styles.button}>Copy Appeal</button>
                <SignInButton mode="modal">
                  <button onClick={handleRegisterForTrial} className={styles.button}>Register for Trial</button>
                </SignInButton>
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default Demo;
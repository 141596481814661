import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useUser, SignInButton } from "@clerk/clerk-react";
import { Helmet } from 'react-helmet';
import styles from '../styles/Templates.module.css';
import { violationTypes } from '../demosets';

const Templates = () => {
  const { isLoaded, isSignedIn } = useUser();
  const [expandedPlatform, setExpandedPlatform] = useState(null);

  const togglePlatform = (platform) => {
    setExpandedPlatform(expandedPlatform === platform ? null : platform);
  };

  const renderTemplateLink = (platform, suspensionType) => {
    const templatePath = `/templates/${platform}/${suspensionType.replace(/\s+/g, '-').toLowerCase()}`;
    
    if (isSignedIn) {
      return (
        <Link 
          to={templatePath}
          className={styles.viewTemplateBtn}
        >
          View Template
        </Link>
      );
    } else {
      return (
        <SignInButton mode="modal">
          <button className={styles.viewTemplateBtn}>Sign In to View</button>
        </SignInButton>
      );
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "E-commerce Appeal Templates",
    "description": "Free templates for appealing account suspensions on various e-commerce platforms. Improve your chances of reinstatement with our professionally crafted appeal letters.",
    "url": "https://appeal.app/templates",
    "mainEntity": {
      "@type": "ItemList",
      "itemListElement": Object.entries(violationTypes).map(([platform, suspensionTypes], index) => ({
        "@type": "ListItem",
        "position": index + 1,
        "name": `${platform.charAt(0).toUpperCase() + platform.slice(1)} Appeal Templates`,
        "url": `https://appeal.app/templates#${platform}`
      }))
    }
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.templateDashboard}>
      <Helmet>
        <title>E-commerce Appeal Templates | Appeal.app</title>
        <meta name="description" content="Access free, professionally crafted appeal templates for various e-commerce platforms. Improve your chances of account reinstatement with our expert guidance." />
        <meta name="keywords" content="e-commerce, appeal templates, account suspension, Amazon, eBay, Etsy, Walmart, reinstatement" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <h1>E-commerce Appeal Templates</h1>
      <div className={styles.introduction}>
        <p>Welcome to our comprehensive collection of e-commerce appeal templates. Whether you're dealing with an account suspension on Amazon, eBay, Etsy, or Walmart, we've got you covered. Our professionally crafted templates are designed to help sellers like you navigate the complex process of appealing account suspensions and policy violations.</p>
        <p>Browse through our platform-specific templates below, and take the first step towards reinstating your selling privileges. Sign in to view and use these templates for free!</p>
      </div>

      <div className={styles.templateGrid}>
        {Object.entries(violationTypes).map(([platform, suspensionTypes]) => (
          <div key={platform} className={styles.platformSection} id={platform}>
            <h2 onClick={() => togglePlatform(platform)} className={styles.platformTitle}>
              {platform.charAt(0).toUpperCase() + platform.slice(1)} Appeal Templates
              <span className={styles.expandIcon}>{expandedPlatform === platform ? '▼' : '▶'}</span>
            </h2>
            {expandedPlatform === platform && (
              <div className={styles.templateList}>
                {suspensionTypes.map((suspensionType) => (
                  <div key={`${platform}-${suspensionType}`} className={styles.templateCard}>
                    <h3>{suspensionType}</h3>
                    {renderTemplateLink(platform, suspensionType)}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Templates;
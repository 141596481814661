import React, { useState, useEffect } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { useSupabase } from '../supabaseClient';
import styles from '../styles/CreditMenu.module.css';

const CreditMenu = ({ onClose, onPurchase }) => {
  const [usedCredits, setUsedCredits] = useState(0);
  const [remainingCredits, setRemainingCredits] = useState(0);
  const { userId } = useAuth();
  const supabase = useSupabase();

  useEffect(() => {
    const fetchCreditInfo = async () => {
      if (userId && supabase) {
        try {
          // Fetch remaining credits
          const { data: creditData, error: creditError } = await supabase
            .from('user_credits')
            .select('credit_balance')
            .eq('user_id', userId)
            .single();

          if (creditError) throw creditError;

          // Fetch used credits
          const { count, error: usedError } = await supabase
            .from('workflows_new')
            .select('id', { count: 'exact' })
            .eq('user_id', userId)
            .eq('appeal_generated', true);

          if (usedError) throw usedError;

          setRemainingCredits(creditData.credit_balance);
          setUsedCredits(count);
        } catch (error) {
          console.error('Error fetching credit info:', error);
        }
      }
    };

    fetchCreditInfo();
  }, [userId, supabase]);
  
  return (
    <div className={styles.creditMenuOverlay} onClick={onClose}>
      <div className={styles.creditMenu} onClick={(e) => e.stopPropagation()}>
        <h2>Credit Information</h2>
        <p>Used Credits: {usedCredits}</p>
        <p>Remaining Credits: {remainingCredits}</p>
        <button onClick={onPurchase} className={styles.purchaseButton}>
          Purchase More Credits
        </button>
        <button onClick={onClose} className={styles.closeButton}>
          Close
        </button>
      </div>
    </div>
  );
};

export default CreditMenu;
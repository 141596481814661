import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, SignInButton } from '@clerk/clerk-react';
import Pricing from '../components/Pricing';
import styles from '../styles/Home.module.css';

const PricingPage = () => {
  const { isSignedIn } = useAuth();
  const navigate = useNavigate();

  const handleTryItFree = () => {
    if (isSignedIn) {
      navigate('/dashboard');
    }
    // If not signed in, the SignInButton component will handle opening the sign-in modal
  };

  return (
    <div className={styles.home}>

      <section className={styles.pricingSection}>
        <div className={styles.container}>
          <Pricing />
        </div>
      </section>
    </div>
  );
};

export default PricingPage;

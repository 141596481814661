import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth, SignInButton, UserButton } from '@clerk/clerk-react';
import { useSupabase } from '../supabaseClient';
import CreditMenu from './CreditMenu';
import Pricing from './Pricing';
import styles from '../styles/Header.module.css';

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [creditBalance, setCreditBalance] = useState(null);
  const [showCreditMenu, setShowCreditMenu] = useState(false);
  const [showPricingPopup, setShowPricingPopup] = useState(false);
  const { isSignedIn, userId } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const supabase = useSupabase();
  const creditMenuRef = useRef(null);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const scrollToSection = (sectionId) => {
    if (location.pathname === '/') {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleManageCredits = () => {
    setShowCreditMenu(!showCreditMenu);
  };

  const handlePurchaseCredits = () => {
    setShowCreditMenu(false);
    setShowPricingPopup(true);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && mobileMenuOpen) {
        setMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [mobileMenuOpen]);

  useEffect(() => {
    const fetchCreditBalance = async (retryCount = 0) => {
      if (isSignedIn && userId && supabase) {
        try {
          const { data, error } = await supabase
            .from('user_credits')
            .select('credit_balance')
            .eq('user_id', userId)
            .single();

          if (error) {
            if (retryCount < 3) {
              setTimeout(() => fetchCreditBalance(retryCount + 1), 2000);
            } else {
              throw error;
            }
          } else {
            setCreditBalance(data.credit_balance);
          }
        } catch (error) {
          console.error('Error fetching credit balance:', error);
        }
      }
    };

    fetchCreditBalance();
  }, [isSignedIn, userId, supabase, location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (creditMenuRef.current && !creditMenuRef.current.contains(event.target)) {
        setShowCreditMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <nav className={`${styles.glassNav} ${mobileMenuOpen ? styles.mobileMenuOpen : ''}`}>
        <div className={styles.navContent}>
          <div className={styles.logoContainer}>
            <Link to="/" className={styles.logo}>
              <img src="/img/logodark.png" alt="Logo" className={styles.logoImg} />
            </Link>
          </div>
          <div className={styles.navLeft}>
            <Link to="/" onClick={() => scrollToSection('demo')} className={styles.navLink}>Demo</Link>
            <Link to="/" onClick={() => scrollToSection('pricing')} className={styles.navLink}>Pricing</Link>
            <Link to="/contact" className={styles.navLink}>Contact</Link>
            <Link to="/blog" className={styles.navLink}>Blog</Link>
            <Link to="/templates" className={styles.navLink}>Templates</Link>
          </div>
          <div className={styles.navRight}>
            {isSignedIn ? (
              <>
                <Link to="/dashboard" className={styles.btnPrimary}>
                  Dashboard
                </Link>
                <button onClick={handleManageCredits} className={styles.btnCredits}>
                  <i className="fas fa-coins"></i> Credits: {creditBalance !== null ? creditBalance : '...'}
                </button>
                <div className={styles.userButtonContainer}>
                  <UserButton />
                </div>
              </>
            ) : (
              <SignInButton mode="modal">
                <button className={styles.btnPrimary}>
                  Sign In
                </button>
              </SignInButton>
            )}
          </div>
          <div className={styles.mobileControls}>
            {isSignedIn && (
              <div className={styles.mobileUserButton}>
                <UserButton />
              </div>
            )}
            <button
              className={`${styles.mobileMenuButton} ${mobileMenuOpen ? styles.mobileMenuButtonOpen : ''}`}
              onClick={toggleMobileMenu}
              aria-label="Toggle mobile menu"
            >
              <span className={styles.mobileMenuIcon}></span>
            </button>
          </div>
        </div>
        <div className={`${styles.mobileMenu} ${mobileMenuOpen ? styles.mobileMenuOpen : ''}`}>
          <div className={styles.mobileMenuContent}>
            <Link to="/" onClick={() => { scrollToSection('demo'); toggleMobileMenu(); }} className={styles.navLink}>Demo</Link>
            <Link to="/" onClick={() => { scrollToSection('pricing'); toggleMobileMenu(); }} className={styles.navLink}>Pricing</Link>
            <Link to="/contact" onClick={toggleMobileMenu} className={styles.navLink}>Contact</Link>
            <Link to="/blog" onClick={toggleMobileMenu} className={styles.navLink}>Blog</Link>
            <Link to="/templates" onClick={toggleMobileMenu} className={styles.navLink}>Templates</Link>
            {isSignedIn && (
              <>
                <Link to="/dashboard" onClick={toggleMobileMenu} className={styles.navLink}>Dashboard</Link>
                <button onClick={() => { handleManageCredits(); toggleMobileMenu(); }} className={styles.btnCredits}>
                  <i className="fas fa-coins"></i> Credits: {creditBalance !== null ? creditBalance : '...'}
                </button>
              </>
            )}
            {!isSignedIn && (
              <SignInButton mode="modal">
                <button className={styles.btnPrimary}>
                  Sign In
                </button>
              </SignInButton>
            )}
          </div>
        </div>
      </nav>
      {showCreditMenu && (
        <CreditMenu
          onClose={() => setShowCreditMenu(false)}
          onPurchase={handlePurchaseCredits}
          creditBalance={creditBalance}
        />
      )}
      {showPricingPopup && (
        <Pricing
          onClose={() => setShowPricingPopup(false)}
          isPopup={true}
          creditPackages={[
            { credits: 1, price: 6.99 },
            { credits: 5, price: 19.99 },
            { credits: 15, price: 49.99 },
          ]}
        />
      )}
    </>
  );
};

export default Header;
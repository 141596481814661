import { createClient } from '@supabase/supabase-js';
import { useClerkSupabaseClient } from './components/ClerkSupabaseAdapter';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  console.error('Supabase URL or Anon Key is missing. Please check your environment variables.');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export function useSupabase() {
  return useClerkSupabaseClient();
}

export const fetchUserData = async (client) => {
  try {
    const { data, error } = await client.auth.getUser();
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
};

export const upsertWorkflow = async (client, workflow) => {
  try {
    const { data, error } = await client
      .from('workflows')
      .upsert(workflow, { onConflict: 'id' })
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error upserting workflow:', error);
    throw error;
  }
};

export const fetchWorkflows = async (client, userId) => {
  try {
    const { data, error } = await client
      .from('workflows')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching workflows:', error);
    throw error;
  }
};

export const deleteWorkflow = async (client, id, userId) => {
  try {
    const { error } = await client
      .from('workflows')
      .delete()
      .match({ id, user_id: userId });

    if (error) throw error;
  } catch (error) {
    console.error('Error deleting workflow:', error);
    throw error;
  }
};
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Templates from './pages/Templates';
import TemplateDetail from './pages/TemplateDetail';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Pricing from './pages/Pricing';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import ClerkSupabaseAdapter from './components/ClerkSupabaseAdapter';
import useGoogleAnalytics from './useGoogleAnalytics';
import styles from './styles/App.module.css';

const ProtectedRoute = ({ children }) => {
  const { isSignedIn, isLoaded } = useAuth();

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  if (!isSignedIn) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function App() {
  const { trackEvent } = useGoogleAnalytics();

  return (
    <ClerkSupabaseAdapter>
      <div className={styles.app}>
        <Header />
        <div className={styles.content}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route 
              path="/dashboard" 
              element={
                <ProtectedRoute>
                  <Dashboard trackEvent={trackEvent} />
                </ProtectedRoute>
              } 
            />
            <Route path="/templates" element={<Templates />} />
            <Route path="/templates/:platform/:templateType" element={<TemplateDetail />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogPost />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </ClerkSupabaseAdapter>
  );
}

export default App;

import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth, SignInButton } from '@clerk/clerk-react';
import { Helmet } from 'react-helmet';
import Demo from '../components/Demo';
import Features from '../components/Features';
import Pricing from '../components/Pricing';
import FAQ from '../components/FAQ';
import styles from '../styles/Home.module.css';

const Home = () => {
  const { isSignedIn } = useAuth();
  const navigate = useNavigate();

  const handleTryItFree = () => {
    if (isSignedIn) {
      navigate('/dashboard');
    }
    // If not signed in, the SignInButton component will handle opening the sign-in modal
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "AI-Powered E-commerce Suspension Appeals",
    "description": "Generate professional e-commerce suspension appeals with AI. Get templates for Amazon, eBay, Etsy, and more. Reinstate your selling account quickly and easily.",
    "url": "https://appeal.app",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://appeal.app/search?q={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };

  const renderTemplateSection = () => {
    return (
      <div className={styles.templateSection}>
        <h2>Expert E-commerce Appeal Templates</h2>
        <p>Access our comprehensive library of suspension appeal templates for all major platforms:</p>
        <button onClick={() => navigate('/templates')} className={styles.btnPrimary}>View All Appeal Templates</button>
      </div>
    );
  };

  const renderBlogHighlights = () => {
    const blogPosts = [
      {
        id: 'creating-effective-amazon-appeal-letter',
        title: 'Master the Art of Amazon Appeal Letters',
        platform: 'Amazon',
      },
      {
        id: 'ultimate-guide-ebay-account-suspensions',
        title: 'Ultimate Guide to eBay Account Suspensions',
        platform: 'eBay',
      },
      {
        id: 'mastering-etsy-shop-reinstatement',
        title: 'Mastering Etsy Shop Reinstatement',
        platform: 'Etsy',
      },
      {
        id: 'walmart-marketplace-suspension-appeal',
        title: 'Walmart Marketplace Suspension Appeals',
        platform: 'Walmart',
      },
    ];

    return (
      <div className={styles.blogHighlights}>
        <h2>Latest Insights for E-commerce Sellers</h2>
        <div className={styles.blogGrid}>
          {blogPosts.map((post) => (
            <div key={post.id} className={styles.blogCard}>
              <h3>{post.title}</h3>
              <p>Platform: {post.platform}</p>
              <Link to={`/blog/${post.id}`} className={styles.readMoreLink}>Read More</Link>
            </div>
          ))}
        </div>
        <Link to="/blog" className={styles.viewAllLink}>View All Articles</Link>
      </div>
    );
  };

  return (
    <div className={styles.home}>
      <Helmet>
        <title>AI-Powered E-commerce Suspension Appeals | Reinstate Your Account | Appeal.app</title>
        <meta name="description" content="Generate professional e-commerce suspension appeals with AI. Get templates for Amazon, eBay, Etsy, and more. Reinstate your selling account quickly and easily with Appeal.app." />
        <meta name="keywords" content="e-commerce, suspension appeals, AI, Amazon, eBay, Etsy, account reinstatement, appeal templates" />
        
        {/* Open Graph meta tags */}
        <meta property="og:title" content="AI-Powered E-commerce Suspension Appeals | Appeal.app" />
        <meta property="og:description" content="Generate professional e-commerce suspension appeals with AI. Reinstate your selling account quickly and easily." />
        <meta property="og:image" content="https://appeal.app/img/og-image.jpg" />
        <meta property="og:url" content="https://appeal.app" />
        <meta property="og:type" content="website" />

        {/* Twitter Card meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="AI-Powered E-commerce Suspension Appeals | Appeal.app" />
        <meta name="twitter:description" content="Generate professional e-commerce suspension appeals with AI. Reinstate your selling account quickly and easily." />
        <meta name="twitter:image" content="https://appeal.app/img/twitter-image.jpg" />

        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <header className={styles.hero}>
        <div className={styles.container}>
          <h1 className={styles.title}>AI-Powered E-commerce Suspension Appeals</h1>
          <p className={styles.subtitle}>Reinstate Your Selling Account in Minutes</p>
          <div className={styles.ctaButtons}>
            {isSignedIn ? (
              <button onClick={() => navigate('/dashboard')} className={styles.btnPrimary}>Go to Dashboard</button>
            ) : (
              <SignInButton mode="modal">
                <button onClick={handleTryItFree} className={styles.btnPrimary}>Try it for Free</button>
              </SignInButton>
            )}
            <button onClick={() => document.getElementById('demo').scrollIntoView({ behavior: 'smooth' })} className={styles.btnSecondary}>See How It Works</button>
          </div>
        </div>
      </header>

      <section className={styles.featuresSection}>
        <div className={styles.container}>
          <h2 className={styles.sectionTitle}>Unlock Your E-commerce Potential</h2>
          <Features />
        </div>
      </section>

      <section id="demo" className={styles.demoSection}>
        <div className={styles.container}>
          <h2 className={styles.sectionTitle}>Experience the Power of AI-Generated Appeals</h2>
          <Demo />
        </div>
      </section>

      <section className={styles.templateIntroSection}>
        <div className={styles.container}>
          {renderTemplateSection()}
        </div>
      </section>

      <section className={styles.pricingSection}>
        <div className={styles.container}>
          <h2 className={styles.sectionTitle}>Affordable Plans for Every Seller</h2>
          <Pricing />
        </div>
      </section>

      <section className={styles.blogSection}>
        <div className={styles.container}>
          {renderBlogHighlights()}
        </div>
      </section>

      <section className={styles.faqSection}>
        <div className={styles.container}>
          <h2 className={styles.sectionTitle}>Frequently Asked Questions</h2>
          <FAQ />
        </div>
      </section>

      <section className={styles.ctaSection}>
        <div className={styles.container}>
          <h2>Ready to Reinstate Your Selling Account?</h2>
          <p>Don't let a suspension hold you back. Get started with our AI-powered appeal generator today!</p>
          {isSignedIn ? (
            <button onClick={() => navigate('/dashboard')} className={styles.btnPrimary}>Go to Dashboard</button>
          ) : (
            <SignInButton mode="modal">
              <button onClick={handleTryItFree} className={styles.btnPrimary}>Try it for Free</button>
            </SignInButton>
          )}
        </div>
      </section>
    </div>
  );
};

export default Home;
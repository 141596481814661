import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from "@clerk/clerk-react";
import styles from '../styles/Dashboard.module.css';
import NewWorkflow from '../components/NewWorkflow';
import SavedWorkflows from '../components/SavedWorkflows';
import AppealWorkflow from '../components/AppealWorkflow';
import UserAccount from '../components/UserAccount';
import Pricing from '../components/Pricing';
import { useSupabase } from '../supabaseClient';

const Dashboard = ({ trackEvent }) => {
  const [workflows, setWorkflows] = useState([]);
  const [currentWorkflow, setCurrentWorkflow] = useState(null);
  const [view, setView] = useState('dashboard');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [creditBalance, setCreditBalance] = useState(null);
  const [isUsingFreeTrial, setIsUsingFreeTrial] = useState(false);
  const [showPricing, setShowPricing] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoaded, isSignedIn, user } = useUser();
  const supabase = useSupabase();

  useEffect(() => {
    if (isLoaded && !isSignedIn) {
      navigate('/sign-in');
    } else if (isLoaded && isSignedIn && user && supabase) {
      fetchUserWorkflows();
      fetchCreditBalance();
    }
  }, [isLoaded, isSignedIn, navigate, user, supabase]);

  useEffect(() => {
    if (location.state && location.state.showPricing) {
      setShowPricing(true);
    }
  }, [location]);

  const fetchCreditBalance = async () => {
    if (!supabase || !user) return;
    try {
      const { data, error } = await supabase
        .from('user_credits')
        .select('credit_balance, is_free_trial')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setCreditBalance(data.credit_balance);
      setIsUsingFreeTrial(data.is_free_trial);
    } catch (err) {
      console.error('Error fetching credit balance:', err);
      setError('Failed to fetch credit balance. Please try again later.');
    }
  };

  const fetchUserWorkflows = async () => {
    if (!supabase || !user) return;
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('workflows_new')
        .select('id, platform, violation_type, created_at, appeal_generated')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setWorkflows(data);
      setError(null);
    } catch (err) {
      console.error('Error fetching workflows:', err);
      setError('Failed to fetch workflows. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchFullAppealDetails = async (workflowId) => {
    if (!supabase || !user) return null;
    try {
      const { data, error } = await supabase
        .from('workflows_new')
        .select('*')
        .eq('id', workflowId)
        .single();

      if (error) throw error;
      return data;
    } catch (err) {
      console.error('Error fetching full appeal details:', err);
      setError('Failed to fetch appeal details. Please try again.');
      return null;
    }
  };

  const handleNewWorkflow = async (workflow) => {
    if (!supabase || !user) return;
    try {
      if (creditBalance <= 0 && !isUsingFreeTrial) {
        setError("You don't have enough credits to create a new appeal. Please purchase more credits.");
        setShowPricing(true);
        return;
      }

      const newWorkflow = {
        ...workflow,
        user_id: user.id,
        created_at: new Date().toISOString(),
        appeal_generated: false,
      };

      const { data, error } = await supabase
        .from('workflows_new')
        .insert(newWorkflow)
        .select()
        .single();

      if (error) throw error;
      setWorkflows([data, ...workflows]);
      setCurrentWorkflow(data);
      setView('edit');
      setError(null);

      // Track new workflow creation
      trackEvent('create_workflow', 'Workflow', 'New workflow created', data.id);
    } catch (err) {
      console.error('Error creating new workflow:', err);
      setError('Failed to create new workflow. Please try again.');
    }
  };

  const handleWorkflowClick = async (workflow) => {
    const fullWorkflow = await fetchFullAppealDetails(workflow.id);
    if (fullWorkflow) {
      setCurrentWorkflow(fullWorkflow);
      setView('edit');
    }
  };

  const handleDeleteWorkflow = async (id) => {
    if (!supabase) return;
    try {
      const workflowToDelete = workflows.find(w => w.id === id);
      if (workflowToDelete && workflowToDelete.appeal_generated) {
        setError("You cannot delete a workflow with a generated appeal.");
        return;
      }

      const { error } = await supabase
        .from('workflows_new')
        .delete()
        .eq('id', id);

      if (error) throw error;
      setWorkflows(workflows.filter(w => w.id !== id));
      if (currentWorkflow && currentWorkflow.id === id) {
        setCurrentWorkflow(null);
        setView('dashboard');
      }
      setError(null);
    } catch (err) {
      console.error('Error deleting workflow:', err);
      setError('Failed to delete workflow. Please try again.');
    }
  };

  const handleSaveWorkflow = async (workflow) => {
    if (!supabase) return;
    try {
      const { data, error } = await supabase
        .from('workflows_new')
        .upsert(workflow)
        .select()
        .single();

      if (error) throw error;
      
      setWorkflows(prevWorkflows => 
        prevWorkflows.map(w => w.id === workflow.id ? data : w)
      );
      
      setCurrentWorkflow(data);
      
      setError(null);

      // Track appeal generation
      if (data.appeal_generated) {
        trackEvent('generate_appeal', 'Appeal', 'Appeal generated', data.id);
      }
    } catch (err) {
      console.error('Error updating workflow:', err);
      setError('Failed to save workflow. Please try again.');
    }
  };

  const handleReturn = () => {
    setCurrentWorkflow(null);
    setView('dashboard');
    fetchCreditBalance(); // Refresh credit balance when returning to dashboard
  };

  const handlePurchaseCredits = () => {
    setShowPricing(true);
  };

  const handlePurchaseComplete = () => {
    setShowPricing(false);
    fetchCreditBalance();
    // Track credit purchase
    trackEvent('purchase_credits', 'Credits', 'Credits purchased');
  };

  const renderContent = () => {
    if (loading) {
      return <div>Loading...</div>;
    }

    switch (view) {
      case 'dashboard':
        return (
          <div className={styles.dashboardLayout}>
            {(creditBalance > 0) ? (
              <div className={styles.newWorkflowSection}>
                <h2>Start New Appeal</h2>
                <NewWorkflow onSave={handleNewWorkflow} />
              </div>
            ) : (
              <div className={styles.purchaseCreditsPrompt}>
                <h2 className={styles.centeredText}>Purchase Credits to Create New Appeals</h2>
                <button onClick={handlePurchaseCredits} className={styles.purchaseButton}>
                  Purchase Credits
                </button>
              </div>
            )}
            <SavedWorkflows
              workflows={workflows}
              onWorkflowClick={handleWorkflowClick}
              onDeleteWorkflow={handleDeleteWorkflow}
            />
          </div>
        );
      case 'edit':
        return currentWorkflow ? (
          <AppealWorkflow
            workflow={currentWorkflow}
            onSave={handleSaveWorkflow}
            onCancel={handleReturn}
            creditBalance={creditBalance}
            isUsingFreeTrial={isUsingFreeTrial}
            onCreditBalanceChange={fetchCreditBalance}
          />
        ) : null;
      default:
        return null;
    }
  };

  if (!isLoaded || !supabase) {
    return <div>Loading...</div>;
  }

  if (!isSignedIn) {
    return <div>Please sign in to access the dashboard.</div>;
  }

  return (
    <div className={styles.dashboard}>
      <h1 className={styles.dashboardTitle}>Appeal Generator Dashboard</h1>
      {error && <div className={styles.error}>{error}</div>}
      <div className={styles.dashboardContent}>
        {renderContent()}
      </div>
      {showPricing && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <button onClick={() => setShowPricing(false)} className={styles.closeButton}>
              &times;
            </button>
            <Pricing onPurchaseComplete={handlePurchaseComplete} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;